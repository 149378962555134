import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Logo from '../../images/MainLogo_Big.png';
import Popup from "../Popup";

import BrandLogo from '../../images/BrandLogo.png';
import MarketLogo from '../../images/StoreLogo.png';
import NodeLogo from '../../images/NodeLogo.png';
import AdminLogo from '../../images/AdminLogo.png';
import EstimateLogo from '../../images/EstimateLogo.png';
import bigData from '../../images/bigData.jpg';
import vrarLogo from '../../images/vrarLogo.jpg';
import logic from '../../images/logic.png';
import AILogo from '../../images/AILogo.png';
import web3 from '../../images/web3.png';
import MyLogo from '../../images/MyLogo.png';
import Telant from '../../images/Telant.webp';
import './style.css';
import './Tab.css';

export default function Navigator(){
    const [isToggled, setIsToggled] = useState(false);
    const [userToggled] = useState(false);
    // 팝업 열리고 닫는거
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleLogoClick = ()=>{
        setIsPopupOpen(true);
    }
    const closePopup = ()=>{
        setIsPopupOpen(false);
    }

      return(
        <header className="MainHeader" isToggled={isToggled} userToggled={userToggled}>
            <div className="headerContainer">
                <div className="logo">
                    <Link to={'/'} className="home" onClick={handleLogoClick}>
                        <div className="logo">
                            <img className="logoImg" src={Logo} />
                        </div>
                    </Link>
                </div>
                <Popup isOpen={isPopupOpen} handleClose={closePopup}/>
                <ul className="header__menulist">
                    <Link to={'/Company'} className="company" style={{ color: '#fff', textDecoration: 'none' }}><li>Company</li></Link>
                    <Link to={'/Service'} className="service" style={{ color: '#fff', textDecoration: 'none' }}><li>Service</li></Link>
                    <Link to={'/Business'} className="business" style={{ color: '#fff', textDecoration: 'none' }}><li>Business</li></Link>
                    <Link to={'/Contact'} className="contact" style={{ color: '#fff', textDecoration: 'none' }}><li>Contact</li></Link>
                </ul>
                <SlideTab/>
            </div>
        </header>
      );
}

 


function SlideTab() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTab = () => setIsOpen(!isOpen);

  return (
    <>
        <SvgButton onClick={toggleTab}/>
      <div className={`tab-container ${isOpen ? 'open' : ''}`}>
        <section style={{width:'100%', display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-evenly',background:'#fff',borderTopLeftRadius:'30px',borderTopRightRadius:'30px',boxShadow:'2px 2px 2px 2px #f1f1f1'}}>
        <h2>AntsNest Apps</h2>
          <a href='https://antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px' src={BrandLogo}></img></a>
          <a href='https://edu.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px'  src={MyLogo}></img></a>
          <a href='https://admin.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px'  src={AdminLogo}></img></a>
          <a href='https://estimate.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px' src={EstimateLogo}></img></a>
          <a href='https://nodemaster.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px'  src={NodeLogo}></img></a>
          <a href='https://store.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px'  src={Telant}></img></a>
          <a href='https://market.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px'  src={MarketLogo}></img></a>
          <a href='https://skyscribe.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px'  src={bigData}></img></a>
          <a href='https://w3.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px'  src={web3}></img></a>
          <a href='https://logic.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px'  src={logic}></img></a>
          <a href='https://wiki.antsnest.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px'  src={vrarLogo}></img></a>
          <a href='https://ai.nodemaster.co.kr'><img style={{borderStyle:'groove',margin:'15px',background:'#fff',borderRadius:'15px', cursor:'pointer'}} width='50px' height='50px'  src={AILogo}></img></a>
        </section>
        <button style={{display:'none'}} onClick={toggleTab}>Close</button>
      </div>
      {/* {isOpen && <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={toggleTab}></div>} */}
    </>
  );
}


function SvgButton({ onClick }) {
    const [hover, setHover] = useState(false);
  
    // 호버 상태에 따라 변경될 인라인 스타일 정의
    const svgStyle = {
      position: 'fixed',
      fill: 'none',
      cursor: 'pointer',
      right:'20px',
      filter: hover ? 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))' : 'none',
    };

    const containerStyle = {
        position: 'relative',
        width: '40px', // SVG의 width와 일치하게 조정
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
  
    return (
        <div style={containerStyle} onClick={onClick}>
            <svg
                width="40" // 여기를 100으로 조정
                height="40" // 여기를 100으로 조정
                style={svgStyle}
                viewBox="0 0 24 24" // viewBox는 그대로 유지하여 SVG 내부 요소의 비율을 유지
                xmlns="http://www.w3.org/2000/svg"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {/* SVG의 원들 */}
                <circle cx="6" cy="6" r="2" fill="#fff" />
                <circle cx="12" cy="6" r="2" fill="#fff" />
                <circle cx="18" cy="6" r="2" fill="#fff" />
                <circle cx="6" cy="12" r="2" fill="#fff" />
                <circle cx="12" cy="12" r="2" fill="#fff" />
                <circle cx="18" cy="12" r="2" fill="#fff" />
                <circle cx="6" cy="18" r="2" fill="#fff" />
                <circle cx="12" cy="18" r="2" fill="#fff" />
                <circle cx="18" cy="18" r="2" fill="#fff" />
            </svg>
        </div>
    );
}
  