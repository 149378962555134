import { useEffect ,useState} from 'react';
import './style.css';


function SlideShowGallery({ images =[]}) {

    const [slideIndex, setSlideIndex] = useState(0);

    // 자동으로 넘기기
    //   useEffect(() => { // 폼로드와 비슷한 개념
        
    //     const interval = setInterval(() => {
    //     setSlideIndex((prevIndex) => 
    //             prevIndex === images.length - 1 ? 0 : prevIndex + 1
    //         );
    //     }, 5000);
    
    //     return () => clearInterval(interval);
    //   }, [images]);

    // 화살표 되돌리기
    // let plusSlidesAuto = (n) => {
    //   let newIndex = slideIndex + n;
    //   if (newIndex > 3) {
    //     console.log(newIndex);
    //     newIndex = 1;
    //   } else if (newIndex < 1) {
    //     console.log(newIndex);
    //     newIndex = 3;
    //   }
    //   showSlides(newIndex);
    // };
 
    
    return (
    <div className="GalleryContainer">
        {/* 슬라이드큰 화면 */}
        <div className='BigSlide'>
            
            {/* <div className="mySlides">
                <div className="numbertext">{slideIndex} / 3</div>
                <img src={DefaultImg1} className='defaultImg'/>
            </div>

            <div className="mySlides" style={{display: 'none'}}>
                <div className="numbertext">{slideIndex} / 3</div>
                <img src={DefaultImg2} className='defaultImg'/>
            </div>

            <div className="mySlides" style={{display: 'none'}}>
                <div className="numbertext">{slideIndex} / 3</div>
                <img src={DefaultImg3} className='defaultImg'/>
            </div> */}

            {/* 화살표 */}
            {/* <div className='arrowBtn'>
                <a className="prev" onClick={()=>plusSlides(-1)}>&#10094;</a>
                <a className="next" onClick={()=>plusSlides(1)}>&#10095;</a>
            </div> */}
            {images.map((image, index) => (
                    <div 
                        className={`mySlides ${index === slideIndex ? 'active' : ''}`}
                        key={index}
                        style={{ display: index === slideIndex ? 'block' : 'none' }}
                    >
                        {/* <div className="numbertext">{index + 1} / {images.length}</div> */}
                        <img src={image} className='defaultImg' style={{width:'108%'}}/>
                    </div>
                ))}
            {/* 화살표 */}
            <div className='arrowBtn'>
                <a 
                    className="prev" 
                    onClick={() => setSlideIndex(slideIndex === 0 ? images.length - 1 : slideIndex - 1)}
                >
                    &#10094;
                </a>
                <a 
                    className="next" 
                    onClick={() => setSlideIndex(slideIndex === images.length - 1 ? 0 : slideIndex + 1)}
                >
                    &#10095;
                </a>
            </div>
        </div>
            

        {/* 슬라이드 작은 화면 */}
        <div className="row">
            {/* <div className="column">
                <div className="demo cursor"  onClick={()=>currentSlide(1)} alt="사업기획">
                    <img src={MbannerImg1} className='MbannerImg'/>
                </div>
            </div>
            <div className="column">
                <div className="demo cursor"  onClick={()=>currentSlide(2)} alt="개요">
                    <img src={MbannerImg2} className='MbannerImg'/>
                </div>
            </div>
            <div className="column">
                <div className="demo cursor"  onClick={()=>currentSlide(3)} alt="AI 홀로그램 결합">
                    <img src={MbannerImg3} className='MbannerImg'/>
                </div>
            </div> */}
            {images.map((image, index) => (
                <div className="column" key={index}>
                    <div 
                        className={`demo cursor ${index === slideIndex ? 'GalleryActive' : ''}`}  
                        onClick={() => setSlideIndex(index)}
                    >
                        <img src={image} className='MbannerImg' style={{width:'72%'}}/>
                    </div>
                </div>
            ))}
        </div>
    </div>
    );
}
  
  export default SlideShowGallery;
