import React,{useState, useEffect} from 'react';
import { Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Navigator from './components/Navigator/index';
import MobileNavigator from './components/Navigator/Mobile';
import Home from './screen/Home/index';
import MobileHome from './screen/Home/Mobile';
import Solution from './screen/Solution/index';
import MobileSolution from './screen/Solution/Mobile';
import MobileService from './screen/Service/Mobile';
import Service from './screen/Service/index';
import NodeMaster from './screen/NodeMaster';
import MobileNodeMaster from './screen/NodeMaster/Mobile';
import MobileFutureIndustry from './screen/FutureIndustry/Mobile';
import FutureIndustry from './screen/FutureIndustry';
import Footer from './components/Footer';
import Company from './screen/COMPANY/index';
import MobileCompany from './screen/COMPANY/Mobile';
import Business from './screen/Business/index';
import MobileBusiness from './screen/Business/Mobile';
import Contact from './screen/CONTACT/index';
import MobileContact from './screen/CONTACT/Mobile';
import Popup from './components/Popup';

function App() {
  // 화면 너비 줄어들때 모바일 화면으로 바뀌는것
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
  useEffect(()=>{
      const checkSize =() =>{
          setIsMobile(window.innerWidth <= 850);
      }
      checkSize();

      window.addEventListener('resize', checkSize);

      return()=>{
          window.removeEventListener('resize',checkSize);
      };
    },[]);
    // 팝업 열리고 닫는거
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(()=>{
      setIsPopupOpen(true);
    },[]);

    const closePopup = () =>{
      setIsPopupOpen(false);
    }

  return (
    <div className="App">
      <div className='App-container'>
        
        <header className="App-header">
          {isMobile ? <MobileNavigator /> : <Navigator />}
        </header>
        <hr style={{ margin:'0rem', border:'1px solid #0F263B' }} width="120%"  />

        <div className='PageContents'>
          <Popup isOpen={isPopupOpen} handleClose={closePopup}/>
          <Routes>
            <Route path="/" element={isMobile ? <MobileHome /> : <Home />} />
            <Route path="/Company" element={isMobile ? <MobileCompany /> : <Company />} />
            <Route path="/Solution" element={isMobile ? <MobileSolution /> : <Solution />} />
            <Route path="/Service" element={isMobile ? <MobileService /> : <Service />} />
            <Route path="/NodeMaster" element={isMobile ? <MobileNodeMaster /> : <NodeMaster />} />
            <Route path="/FutureIndustry" element={isMobile ? <MobileFutureIndustry /> : <FutureIndustry />} />
            <Route path="/Business" element={isMobile ? <MobileBusiness /> : <Business />} />
            <Route path="/Contact" element={isMobile ? <MobileContact /> : <Contact />} />
          </Routes> 
          </div>    
        <footer>
        <hr style={{ margin: "0rem", border:'1px solid #0F263B' }} width="100%"  />

          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default App;
