import './style.css';
import HrCard from '../../components/HrCard/index';
import ParkParkMy from '../../images/ParkParkMy.jpg';
import butters from '../../images/butters.jpg';
import Proudin from '../../images/Proudin.jpg';
import Future from '../../images/Future.jpg';
import Woojong from '../../images/Woojong.jpg';
import Hollis from '../../images/Hollis.jpg';
import pghpgh from '../../images/pghpgh.png';
import Eunji from '../../images/Eunji.png';
import Yeonsu from '../../images/yeonsu.png';
import Dasom from '../../images/Dasom.jpg';
import Nahee from '../../images/Nahee.jpg';
import Dexterity from '../../images/Dexterity.jpg';
import Taeyoon from '../../images/Taeyoon.jpg';
import AnHK from '../../images/AnHK.jpg';
import KHJ from '../../images/KHJ.jpg';
import BusinessChart from'../../images/businessChart.png';


export default function Company(){
    return(
        <div className="CompanyContents">
            <div className="HomeContainer">
                {/* 상단 텍스트 */}
                <div className="CompanyTopTxt">
                    <h1>About Us</h1>
                    <p>ANTSNEST 그룹의 개발팀을 소개합니다.</p>
                </div>

                {/* 카드 */}
                <div className='HrCardContainer'>
                    <HrCard
                        Photo={Dexterity}
                        Name="용수현"
                        Work="Project Leader"
                        Title="프로젝트 그룹 대표"
                        Work2="Rnd 개발,인프라 담당"
                        Email="ysh1103korea@antsnest.co.kr"
                    />
                    <HrCard
                        Photo={Proudin}
                        Name="박상욱"
                        Work="Project Leader"
                        Title="프로젝트 그룹 대표"
                        Work2="서버 및 데이터베이스 담당"
                        Email="psw88@antsnest.co.kr"
                    />
                    <HrCard
                        Photo={butters}
                        Name="최용호"
                        Work="Project Member"
                        Title="Web&App Developer"
                        Work2="Spa 웹앱 개발 담당"
                        Email="butters88@naver.com"
                    />
                    <HrCard
                        Photo={ParkParkMy}
                        Name="박명준"
                        Work="Project Member"
                        Title="Application Developer"
                        Work2="Java / .Net 응용 개발"
                        Email="ParkParkMy@antsnest.co.kr"
                    />
                    <HrCard
                        Photo={Future}
                        Name="이혜림"
                        Work="Project Member"
                        Title="Application Developer"
                        Work2="WPF /.Net 응용 개발"
                        Email="gPfla8966@gmail.com"
                    />
                    <HrCard
                        Photo={Woojong}
                        Name="진우종"
                        Work="Project Member"
                        Title="Web&App Developer"
                        Work2="Spa 웹앱 개발 담당"
                        Email="woojong67@naver.com"
                    />
                    <HrCard
                        Photo={Hollis}
                        Name="김연주"
                        Work="Project Member"
                        Title="3D Designer"
                        Work2="3D 모델링 리소스"
                        Email="duswn9211@naver.com"
                    />
                    <HrCard
                        Photo={pghpgh}
                        Name="박경화"
                        Work="Project Member"
                        Title="리눅스,데이터베이스"
                        Work2="인프라 구축 설계"
                        Email="rudghk435@naver.com"
                    />
                    <HrCard
                        Photo={Eunji}
                        Name="이은지"
                        Work="Project Member"
                        Title="App&Web Developer"
                        Work2="프론트&백엔드 개발"
                        Email="ieunji470@antsnest.co.kr"
                    />
                    <HrCard
                        Photo={Yeonsu}
                        Name="이연수"
                        Work="Project Member"
                        Title="App&Web Developer"
                        Work2="프론트&백엔드 개발"
                        Email="djfksdl@antsnest.co.kr"
                    />
                    <HrCard
                        Photo={Dasom}
                        Name="김다솜"
                        Work="Project Member"
                        Title="Marketing&UI/UX Designer"
                        Work2="마케팅, UI/UX디자인"
                        Email="som1213@antsnest.co.kr"
                    />
                    <HrCard
                        Photo={Nahee}
                        Name="김나희"
                        Work="Project Member"
                        Title="Component Developer"
                        Work2="FrameWork Architecture"
                        Email="madnarii@antsnest.co.kr"
                    />
                    <HrCard
                        Photo={Taeyoon}
                        Name="허태윤"
                        Work="Project Member"
                        Title="Fa devops Developer"
                        Work2="FrameWork Architecture"
                        Email="benuze@antsnest.co.kr"
                    />
                    <HrCard
                        Photo={AnHK}
                        Name="안형기"
                        Work="Project Member"
                        Title="App&Web Developer"
                        Work2="프론트&백엔드 개발"
                        Email="gudrl96@antsnest.co.kr"
                    />
                    <HrCard
                        Photo={KHJ}
                        Name="김형진"
                        Work="Project Member"
                        Title="App&Web Developer"
                        Work2="기술강의&PC Repair"
                        Email="khjin0313@antsnest.co.kr"
                    />
                </div>

                {/* 차트 */}
                <div className="CompanyChartTxt">
                    <h1>Business Organization Chart</h1>
                    <img src={BusinessChart}/>
                </div>
            </div>
        </div>
    );
}