import FutureIndustryPage from '../../images/FutureIndustryPage.png';
import FutureTitle from '../../images/FutureTitle.png';
import './Mstyle.css';

export default function FutureIndustry(){
    return (<>
    <div className="headerTitle">
        <img src={FutureTitle} style={{width:'100%'}} />
        <p>미래산업</p>
    </div>
    <img src={FutureIndustryPage} style={{width:'100%'}}></img>
    </>)
}