import React, { useEffect } from 'react';
import RedirectGallery from "../../components/RedirectGallery";
import Intro from '../../images/Intro.png';
import Footer from '../../images/FooterLogo.png';
import backgroundImage from '../../images/main_Back3.jpg';
import value1 from '../../images/challenge.png';
import value2 from '../../images/careful.png';
import value3 from '../../images/training.png';
import './Mstyle.css';

export default function Home() {

    //애니메이션 js
    useEffect(() => {
        const elements = document.querySelectorAll('.coreValues > div');
        elements.forEach((element, index) => {
          element.style.animationDelay = `${1 + index * 1}s`; 
          element.classList.add('animate'); 
        });
      }, []);
    


    const backgroundImg = {
        backgroundImage:`url(${backgroundImage})`, 
        backgroundSize:'cover',
        backgroundPosition:'center 95%', 

    }


    return (<>
        
        <div className='homePage'>
            {/* 사각 메뉴 */}
            <RedirectGallery />
            <section className="backgroundImg" >
                <div className="mainPage" >
                    {/* 첫번째 Content */}
                    <div>
                        <div className="mainTitle">VR/AR을 통한 교육의 혁신,
                            <div><span className="mainTitleAnts">앤츠네스트</span>입니다</div>
                        </div>
                        <div className="mainSub">
                            더 많은 사람들에게 교육의 기회를 제공하기 위해<br />
                            VR/AR기술을 활용하여 교육의 문턱을 낮추고,<br />
                            대중화하는 것을 목표로 합니다.
                        </div>
                        <div className="mainSubR" >
                            <span className="AntsnestSTR" >ANTSNEST</span>는 <br />해당 사업을 위해 7년 이상 연구해왔으며,
                            <br />많은 장비 보유로 VR/AR 개발 업체의 선두 주자입니다.
                        </div>
                    </div>

                    <hr width='100%' size='1' />

                    {/* 두번째 Content */}
                    <div>
                        <div className="coreValues" >
                            <div className="coreValueTitle" >Core Values</div>

                            <div className="challenge">
                                <div className="challengeTitle" > 
                                <img src={value1}/>
                                <p>도전</p>
                                </div>
                                <div className="detail" >아직 누구도 가보지 않았던, 알려지지 않은 영역으로의 탐험에 주저하지 않습니다. <br />우리는 끊임없이 새로운 가능성을 찾아 나가며, 이를 통해 우리의 고객, 파트너,<br />
                                    그리고 사회에 혜택을 제공하려 노력합니다.</div>
                            </div>

                            <div className="careful" >
                                <div className="carefulTitle" >
                                    <img src={value2}/>
                                    <p>세심함</p>
                                </div>
                                <div className="detail" >앤츠네스트는 보이지 않는 일상의 보석에서 광채를 발견합니다.<br />
                                    모두가 눈에 보이는 큰 것에 집중할 때,<br />우리는 모래알과 같은 작고 사소한 부분에서 가치를 발견해냅니다.</div>
                            </div>

                            <div className="talent" >
                                <div className="talentTitle" > 
                                    <img src={value3}/>
                                    <p>인재양성</p>
                                </div>
                                <div className="detail" >인재의 발굴과 양성이 기업의 미래를 좌우하는 것은 자명한 사실이나, 이는 단지<br />회사 내부의 인재들에게만 국한되는 것이 아닙니다.
                                    우리의 가치는 사회 전체에 걸쳐<br />인재를 발굴하고, 능력을 개발하고, 가능성을 키워나가는 데에 있습니다.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 하단 로고 */}
            <img src={Footer} className='footerImg'></img>
        </div>
    </>)
}