import React, {useEffect} from 'react';
import './style.css';
import phoneCall from '../../images/free-icon-smartphone-call-15874.png';
import companyCall from '../../images/free-icon-telephone-49181.png';
import down from '../../images/Vector 1.png';

export default function Popup({ isOpen, handleClose }){
    const [doNotShow ,setDoNotShow] = React.useState(false);

    useEffect(() => {
        const hidePopupTime = localStorage.getItem('hidePopupUntil');
        if (hidePopupTime && new Date().getTime() < new Date(hidePopupTime).getTime()) {
            setDoNotShow(true);
        }
    }, []);

    const handleCloseWidthCheckbox = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1); // 현재 날짜에서 하루를 추가
        localStorage.setItem('hidePopupUntil', tomorrow); // 내일 날짜를 localStorage에 저장
        setDoNotShow(true);
        handleClose();
    };

    if(!isOpen || doNotShow) return null;
    return(
        <div id='myModal' className='modal'>
            <div className='modal-content'>
                <p>
                    <span className='SizeUp'>앤츠네스트</span>에 오신 것을 환영합니다. <br/>
                    여기에서 우리는 아이디어를 디지털 현실로 전환합니다. <br/>
                </p>
                <p>    
                    <span className='BoldUp'>웹사이트, 애플리케이션, 모바일 앱 개발을 전문</span>으로 하며,&nbsp; <br className='nonBr'/>
                    고객의 특별한 요구에 맞춘 <span className='BoldUp'>맞춤형 솔루션을 제공</span>합니다.
                </p>
                <p>
                    혁신에서의 전문성 , 맞춤형 솔루션  반응형 디자인 및 기능<br className='nonBr'/>
                    오늘 저희에게 연락하여 귀하의 프로젝트 아이디어를 <br className='nonBr2'/>
                    성공적인 디지털 솔루션으로 변환하는 방법을 알아보세요!<br/>
                </p>
                <hr style={{margin:'3%'}}/>
                <div className='PopBottomContainer'>
                    <div className='popupBotm'>
                        <p style={{margin:'0 0 0 3%'}}>
                            <img src={phoneCall}/>전화번호 : 010-7149-8147<br/>
                            <img src={companyCall}/>회사번호 : 070-7797-0814
                        </p>
                        <a className='PopupDown' href={'https://antsnest.co.kr/files/Antsnest_SI.pptx'}download="Antsnest_SI.pptx">
                            앤츠네스트 소개 <img src={down}/>
                        </a>
                    </div>
                    <div className='popupCheck'>
                        <input type='checkbox' onChange={()=> handleCloseWidthCheckbox()}/>
                        <label>하루동안 보지 않기</label>
                        <input type='checkbox' onClick={handleClose}/>
                        <label className='close'>닫기</label>
                        {/* <span className='close' onClick={handleClose}>&times;</span> */}
                    </div>
                </div>
            </div>
        </div>
    );
}