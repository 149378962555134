import React from 'react';
import './Mstyle.css';
import { useState } from 'react';
import {reqGet,reqPost} from '../../Service';
import Txt1 from '../../images/Contact.png';
import Txt2 from '../../images/DEVELOPER CENTER.png';
import Txt3 from '../../images//ANTSNEST.png';



export default function Contact() {
    const [mailUser,setMailUser]=useState({});
    const [mailAddress,setMailAddress]=useState({});
    const [contents,setContents]=useState({});

    const contentHeaderStyle = {
        width: '100%',
        display: 'flex',
        justifyContent:'center',
        flexDirection:'column',
        color:'#fff',

    }

    return (
        <div className='contactPage'>
            <div className='contentHeader' style={contentHeaderStyle}>
                <div className='txtBox'>
                    <img className='headerImg' src={Txt1}></img>
                    <img className='headerImg2' src={Txt2}></img>
                    <img className='headerImg3' src={Txt3}></img>
                </div>
                <div className='mainIntro'>ANTSNEST와 함께하세요.</div>
            </div>

            <hr style={{ marginTop: "3rem", border:'1px solid #0F263B' }} width="100%"  />

            <section>
                <form id="contact-form">
                    <div class="form-group">
                        <label for="name">신청인(회사명)</label>
                        <input className='underline' type="text" id="name" name="name"  onChange={(e)=>setMailUser(e.target.value)} required />
                    </div>

                    <div class="form-group">
                        <label for="email">이메일</label>
                        <input className='underline' type="email" id="email" name="email" onChange={(e)=>setMailAddress(e.target.value)}  required />
                    </div>
                    <div class="form-group">
                        <label for="message">문의사항</label>
                        <textarea id="message" name="message" rows="8"  onChange={(e)=>setContents(e.target.value)}   required></textarea>
                    </div>
                    <button type="button" className='contaxtSubmitBtn' onClick={()=>reqPost('https://antsnest.co.kr/request/sendMail',{mailUser,mailAddress,contents},()=>{alert('신청을 완료했습니다.')})}>상담 신청하기</button>
                </form>
            </section>
        </div>
    );
}