import "./style.css";
import BusinessBtmImg from "../../images/BusinessBottom.png";
//import { Carousel } from "react-responsive-carousel";
import eduantsnest from "../../images/eduantsnest.gif";
import estimate from "../../images/estimate.gif";
import hosting from "../../images/hosting.gif";
import nodemaster from "../../images/nodemaster.gif";
import classroom from "../../images/class.gif";
import brandmain from "../../images/brandmain.gif";
import brandcompany from "../../images/brandcompany.gif";
import brandservice from "../../images/brandservice.gif";

import React,{useState, useEffect} from "react";

export default function Business() {
    /**이미지 데이터들 */
    const imageData = [
        { label: 'nodemaster', alt: 'image1', src: nodemaster },
        { label: 'hosting', alt: 'image2', src: hosting },
        { label: 'classroom', alt: 'image3', src: classroom },
        { label: 'estimate', alt: 'image4', src: estimate },
        { label: 'eduantsnest', alt: 'image5', src: eduantsnest },
        { label: 'brandmain', alt: 'image6', src: brandmain },
        { label: 'brandcompany', alt: 'image7', src: brandcompany },
        { label: 'brandservice', alt: 'image8', src: brandservice },

      ];
    
      const [currentSlide, setCurrentSlide] = useState(0);
      const [isHovering, setIsHovering] = useState(false);

      /** 슬라이드 실행 */
      useEffect(() => {
        let timer;
        if (!isHovering) {
            timer = setInterval(() => {
                setCurrentSlide(prev => (prev + 1) % imageData.length);
            }, 4000); // Rotate every 4 seconds
        } else {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [isHovering]);

    
      /**마우스 오버 시 회전 멈추고, 1.3 스케일 효과 */
      const handleMouseEnter = () => {
        setIsHovering(true);
      };
      /** 마우스 떠나면 다시 재생 */
      const handleMouseLeave = () => {
        setIsHovering(false);
      };
      /**이전 버튼 */
      const handlePrevSlide = () => {
        setCurrentSlide(prev => (prev === 0 ? imageData.length - 1 : prev - 1));
      };
      /** 다음 버튼 */
      const handleNextSlide = () => {
          setCurrentSlide(prev => (prev + 1) % imageData.length);
      };


      
  return (
    <div className="BusinessContents">
      <div className="HomeContainer">
        {/* 상단 텍스트 */}
        <div className="BusinessTopTxt">
          <h1>Developer Center</h1>
          <h1>ANTSNEST</h1>
          <p>ANTSNEST 그룹의 사업을 소개합니다.</p>
        </div>

        <div className="imgslider"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <button className="slider-arrow left" onClick={handlePrevSlide}>&lt;</button>

                {imageData.map((image, index) => (
                    <img
                        key={image.alt}
                        src={image.src}
                        alt={`Slide ${index}`}
                        style={{
                            transform: `rotateY(${(index - (currentSlide % imageData.length)) * 45}deg) translateZ(400px) ${isHovering && index === currentSlide % imageData.length ? "scale(1.3)" : ""}`,
                            transition: 'transform 1s ease',
                        }}
                    />
                ))}
                <button className="slider-arrow right" onClick={handleNextSlide}>&gt;</button>
            </div>

        {/* 중간 텍스트 */}
        <div className="BusinessMidTxt">
          <h1>Software</h1>
          <p>
            <span>앤츠네스트</span>는 파트너사의 작은 의견에도 귀 기울이고,
            <br />
            파트너사의 성공을 위해 끊임없이 노력하는 기업이 되겠습니다.
          </p>
          <p>당신의 성공 파트너 앤츠네스트와 함께하세요.</p>
        </div>
        {/* 마지막 이미지 */}
        <div className="BusinessBottom">
          <img src={BusinessBtmImg} />
        </div>
      </div>
    </div>
  );
}
